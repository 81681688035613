import React, { useState, useEffect, useContext } from 'react';
import { Device } from '../types/device';
import { Api } from '../services/api';
import { SessionContext } from './session-context';

export interface IDeviceContext {
  devices: Array<Device>;
  getDevice(name: string): Device | undefined;
}

type Props = {
  children?: React.ReactNode;
};
const deviceContext: IDeviceContext = {
  devices: [],
  getDevice: function (name) {
    const devices = this.devices.filter((device) => device.name === name);
    if (devices.length > 0) {
      return devices[0];
    }
    return undefined;
  },
};

export const DeviceContext = React.createContext(deviceContext);

const DeviceProvider = ({ children }: Props) => {
  const sessionContext = useContext(SessionContext);
  const [devices, setDevices] = useState<Array<Device>>([]);

  useEffect(() => {
    async function getDeviceInfo() {
      if (sessionContext.details.site === undefined) {
        console.error('Error occurred getting the site from the logged in user');
        setDevices([]);
      } else {
        const site = await Api.getInstance().getSite(sessionContext.details.site);
        const keys = Object.keys(site.devices);
        const items: Array<Device> = [];
        keys.forEach((key) => {
          const device = site.devices[key];
          items.push(device);
        });
        items.sort((a, b) => (a.deviceName.toLowerCase() > b.deviceName.toLowerCase() ? 1 : -1));
        items.forEach((i) => console.log('item', i));
        setDevices(items);
      }
    }
    getDeviceInfo();
  }, [sessionContext]);

  const value: IDeviceContext = {
    devices,
    getDevice: function (name) {
      const devices = this.devices.filter((device) => device.name === name);
      if (devices.length > 0) {
        return devices[0];
      }
      return undefined;
    },
  };
  return <DeviceContext.Provider value={value}>{children}</DeviceContext.Provider>;
};

export default DeviceProvider;
