import { Camera } from '../types/camera';
import { Device } from '../types/device';
import { Site } from '../types/site';
import { Location } from '../types/location';
import { AlertResponse } from '../types/alerts';
import { UpdateIssue, UpdateIssuesStatus } from '../types/issue';
import { useId } from 'react';

export class Api {
  private static instance: Api;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  private static generateRandomId(length: number): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  }

  public static getInstance(): Api {
    if (!Api.instance) {
      Api.instance = new Api();
    }
    return Api.instance;
  }

  public async getSite(siteName: string): Promise<Site> {
    const encSiteName = encodeURIComponent(siteName);
    const response = await fetch(`https://api.dashboard.onsightops.com/site/${encSiteName}`);
    if (response.status !== 200) {
      throw new Error(`Invalid response status: ${response.status}`);
    }
    const data = await response.json();
    const site: Site = {
      name: siteName,
      devices: {},
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (data as Array<any>).forEach((deviceItem: any) => {
      const device: Device = {
        name: deviceItem.name,
        deviceName: deviceItem.deviceName,
        cameras: [],
      };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      deviceItem.cameras.forEach((camera: any) => {
        if (camera.frame !== undefined) {
          device.cameras?.push({
            name: camera.name,
            id: camera.frame.id,
            url: camera.frame.url,
            captured: camera.frame.captured,
            device: device.name,
            deviceName: device.deviceName,
          });
        } else {
          device.cameras?.push({
            name: camera.name,
            id: Api.generateRandomId(10),
            device: device.name,
            deviceName: device.deviceName,
          });
        }
      });
      site.devices[deviceItem.name] = device;
    });
    return site;
  }

  public async getLocation(siteName: string): Promise<Site> {
    const encSiteName = encodeURIComponent(siteName);
    const response = await fetch(`https://api.dashboard.onsightops.com/location/${encSiteName}`);
    if (response.status !== 200) {
      throw new Error(`Invalid response status: ${response.status}`);
    }
    const data = await response.json();
    const site: Site = {
      name: siteName,
      devices: {},
    };

    const keys = Object.keys(data);
    keys.forEach((key) => {
      const value = data[key];
      const device: Device = {
        name: key,
        deviceName: key,
        cameras: [],
      };
      Object.keys(value).forEach((deviceKey) => {
        if (deviceKey === 'name') {
          device.deviceName = value[deviceKey];
        }
      });
      site.devices[key] = device;
    });
    return site;
  }

  public async getCameraFrame(camera: Camera): Promise<Camera> {
    const encDevice = encodeURIComponent(camera.device);
    const encCamera = encodeURIComponent(camera.name);
    const response = await fetch(`https://api.dashboard.onsightops.com/cameraframe?device=${encDevice}&camera=${encCamera}`);
    if (response.status !== 200) {
      throw new Error(`Invalid response status: ${response.status}`);
    }
    const data = await response.json();
    return {
      name: camera.name,
      device: camera.device,
      deviceName: camera.deviceName,
      captured: data.captured,
      url: data.url,
      id: data.id,
    };
  }

  public async getTelemetry(robotName: string): Promise<Location> {
    const encRobotName = encodeURIComponent(robotName);
    const response = await fetch(`https://api.dashboard.onsightops.com/telemetry?robot=${encRobotName}`);
    if (response.status !== 200) {
      throw new Error(`Invalid response status: ${response.status}`);
    }
    const data = await response.json();
    return data as Location;
  }

  public async getAlerts(robotName: string, page?: number, count?: number): Promise<AlertResponse> {
    const encRobotName = encodeURIComponent(robotName);
    const pageNumber = page ?? 1;
    const totalResults = count ?? 10;
    const response = await fetch(`https://api.dashboard.onsightops.com/issues/${encRobotName}?page=${pageNumber}&count=${totalResults}`);
    if (response.status !== 200) {
      throw new Error(`Invalid response status: ${response.status}`);
    }
    const data = await response.json();
    return data as AlertResponse;
  }

  public async getSubscriptionPhoneNumber(site: string | undefined, email: string | undefined): Promise<string> {
    if (site === undefined || email === undefined) {
      console.warn('Site or email is undefined');
      return '';
    }
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ site, email }),
    };
    const response = await fetch('https://api.dashboard.onsightops.com/subscription/subscriptions', requestOptions);
    if (response.status !== 200) {
      console.error('Error occurred fetching subscriptions', response);
      return '';
    }
    const data = await response.json();
    return data.phoneNumber;
  }

  public async subscribe(site: string | undefined, email: string | undefined, phoneNumber: string) {
    if (site === undefined || email === undefined) {
      console.warn('Site or email is undefined');
      return;
    }
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ site, email, phoneNumber }),
    };
    const response = await fetch('https://api.dashboard.onsightops.com/subscription/subscribe', requestOptions);
    if (response.status !== 200) {
      console.error('Error occurred subscribing', response);
      throw new Error(`Any unexpected error occurred while subscribing.`);
    }
  }

  public async unsubscribe(site: string | undefined, email: string | undefined) {
    if (site === undefined || email === undefined) {
      console.warn('Site or email is undefined');
      return;
    }
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ site, email }),
    };
    const response = await fetch('https://api.dashboard.onsightops.com/subscription/unsubscribe', requestOptions);
    if (response.status === 400) {
      throw new Error('Already unsubscribed');
    }
    if (response.status !== 200) {
      console.error('Error occurred unsubscribing', response);
      throw new Error(`Any unexpected error occurred while unsubscribing.`);
    }
  }

  public async getProjects() {
    const response = await fetch('https://api.data.dashboard.onsightops.com/projects', {
      method: 'GET',
      headers: {
        Authorization: window.localStorage['token'],
      },
    });
    if (response.ok) {
      return await response.json();
    }
    throw new Error('Unable to get Projects');
  }

  public async getIssues(projectId: string, status: string, dateRange: [Date, Date] | null) {
    let response: Response;
    if (dateRange === null) {
      response = await fetch(`https://api.data.dashboard.onsightops.com/issues?projectId=${projectId}&status=${status}`, {
        method: 'GET',
        headers: {
          Authorization: window.localStorage['token'],
        },
      });
    } else {
      response = await fetch(
        `https://api.data.dashboard.onsightops.com/issues?projectId=${projectId}&status=${status}&startDate=${dateRange[0].toISOString()}&endDate=${dateRange[1].toISOString()}`,
        {
          method: 'GET',
          headers: {
            Authorization: window.localStorage['token'],
          },
        }
      );
    }
    if (response.ok) {
      return await response.json();
    }
    throw new Error('Unable to get Issues');
  }

  public async getImageUrl(bucket: string, path: string): Promise<{ url: string }> {
    const response = await fetch(`https://api.data.dashboard.onsightops.com/image?bucket=${bucket}&key=${path}`, {
      method: 'GET',
      headers: {
        Authorization: window.localStorage['token'],
      },
    });
    if (response.ok) {
      return await response.json();
    }
    throw new Error('Unable to get Projects');
  }

  public async updateIssue(update: UpdateIssue) {
    const response = await fetch('https://api.data.dashboard.onsightops.com/issue', {
      method: 'POST',
      headers: {
        Authorization: window.localStorage['token'],
      },
      body: JSON.stringify(update),
    });
    if (response.ok) {
      return await response.json();
    }
    const error = await response.json();
    throw new Error(error);
  }

  public async updateIssuesStatus(update: UpdateIssuesStatus) {
    const response = await fetch('https://api.data.dashboard.onsightops.com/issues/status', {
      method: 'POST',
      headers: {
        Authorization: window.localStorage['token'],
      },
      body: JSON.stringify(update),
    });
    if (response.ok) {
      return await response.json();
    }
    const error = await response.json();
    throw new Error(error);
  }

  /**
   * Copies missing elements from the cpySite to the srcSite.
   * @param srcSite The source site that will have the data updated.
   * @param cpySite The copy site that will have the data read from.
   */
  public copySite(srcSite: Site, cpySite: Site) {
    const keys = Object.keys(cpySite.devices);
    keys.forEach((key) => {
      if (srcSite.devices[key] === undefined) {
        srcSite.devices[key] = cpySite.devices[key];
      } else {
        const cpyDevice = cpySite.devices[key];
        if (cpyDevice.location !== undefined) {
          srcSite.devices[key].location = cpyDevice.location;
        }
        if (cpyDevice.cameras !== undefined && cpyDevice.cameras.length > 0) {
          srcSite.devices[key].cameras = cpyDevice.cameras;
        }
      }
    });
  }
}
